<template>
	<div class="dashboard-content" data-app>
		<!-- Content -->
		<v-card>
			<div class="filters">
				<h3 class="text-center">
					{{ title ? title : 'My CRUD'
					}}<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								color="#003a56"
								dark
								class="mb-2"
								style="float: right"
								@click.prevent="handleCreate()"
								>Crear
							</v-btn>
						</template>
						<span>Crear {{ editText }}</span>
					</v-tooltip>
				</h3>

				<label>Filtrar por:</label>

				<v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2">
					<v-row class="mx-0" style="max-width: calc(100% - 90px)">
						<v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
							<v-text-field dense solo hide-details="auto" label="Búsqueda" v-model="filters.search"></v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
							<multiselect
								track-by="id"
								label="name"
								deselect-label=""
								select-label=""
								placeholder="Responsable"
								:custom-label="fullName"
								v-model="filters.userId"
								:options="users"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="false"
								:preserve-search="false"
							></multiselect>
						</v-col>
						<v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
							<multiselect
								track-by="id"
								label="name"
								deselect-label=""
								select-label=""
								placeholder="País"
								:custom-label="countryName"
								v-model="filters.countryId"
								:options="countries"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="false"
								:preserve-search="false"
							></multiselect>
						</v-col>
						<v-col cols="12" lg="2" md="2" sm="6" style="padding: 1px 2px 1px 2px">
							<multiselect
								track-by="id"
								label="name"
								deselect-label=""
								select-label=""
								placeholder="Destacada"
								v-model="filters.outstanding"
								:options="outstandingOptions"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="true"
								:preserve-search="false"
							></multiselect>
						</v-col>
						<v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
							<multiselect
								style="min-width: 80px"
								track-by="id"
								label="name"
								deselect-label=""
								select-label=""
								placeholder="Activa"
								v-model="filters.active"
								:options="activeOptions"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="false"
								:preserve-search="false"
							></multiselect>
						</v-col>
						<v-btn
							color="#003a56"
							style="min-width: auto; position: absolute; right: 50px; height: 25px"
							dark
							class="px-1 py-1"
							v-if="filterActivated"
							@click.prevent="
								filterActivated = false
								cleanFilters()
								getCompanies()
							"
						>
							<v-icon dark> mdi-delete </v-icon>
						</v-btn>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									color="#003a56"
									style="min-width: auto; position: absolute; right: 10px; height: 25px"
									dark
									class="px-1 py-1"
									@click.prevent="
										filterActivated = true
										getCompanies()
									"
									><v-icon dark> mdi-filter </v-icon>
								</v-btn>
							</template>
							<span>Filtrar</span>
						</v-tooltip>
					</v-row>
					<v-row>
						<v-col cols="12" lg="3" md="4" sm="6" class="mt-3">
							<v-text-field type="date" hide-details="auto" label="Fecha de Inicio" v-model="filters.date.startAt">
								<template v-slot:message> Fecha de Inicio </template></v-text-field
							>
						</v-col>
						<v-col cols="12" lg="3" md="4" sm="6" class="mt-3">
							<v-text-field type="date" hide-details="auto" label="Fecha de Cierre" v-model="filters.date.endAt"
								><template v-slot:label>
									<span> Fecha de Culminación </span>
								</template></v-text-field
							>
						</v-col>
					</v-row>
				</v-form>
			</div>
			<v-row no-gutters justify='end'>
				<v-btn
								:disabled="loadingExcel || !totalItems > 0"
								class="ml-auto px-0"
								small
								text
								color="primary"
								style="overflow: hidden"
								@click='exportExcel'
							>
								<span style="z-index: 2" class="px-3">Exportar <v-icon>mdi-microsoft-excel</v-icon></span>
								<v-progress-linear
									style="position: absolute; width: 100%; z-index: 1"
									:value="progressValue * 100"
									height="100"
									color="#81C784"
									background-color="transparent"
									:indeterminate="false"
								/>
							</v-btn>
			</v-row>

			<simple-list
				:title="''"
				:dense="true"
				:headers="headers"
				:items="items"
				:totalItems="totalItems"
				:itemsPerPage="itemsPerPage"
				:loadingText="loadingText"
				:itemKey="'id'"
				:hideFooter="true"
				:hideHeader="false"
				:multiSort="false"
				:loading="loading"
				:class="'my-4 pb-2'"
				:sortable="false"
				:page="page"
				:pages="pages"
				:extraButtoms="extraButtoms"
				:showCreate="false"
				:showDelete="true"
				:buttonLink="'empresas/ficha'"
				:orderOptions="orderOptions"
				@create:item="handleCreate()"
				@edit:item="handleEdit($event)"
				@delete:item="deleteItem($event)"
				@update:page="pagination($event)"
				@orderEvent="handleOrder($event)"
				@handleQR="handleQR($event)"
			/>
		</v-card>
		<modal name="crudModal" :height="'70%'" :width="'80%'" :adaptive="true">
			<h3 class="text-center" v-if="flow == 'create'">Crear {{ editText }}</h3>
			<h3 class="text-center" v-if="flow == 'edit'">Editar {{ editText }}</h3>

			<div class="card" style="padding: 2rem; margin: 20px">
				<span v-if="flow == 'edit' && item.created_at"
					>Fecha de creación:
					{{
						createdAt.toLocaleDateString('es-VE', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})
					}}. {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
				>
				<h5 style="margin-bottom: 20px">Datos generales</h5>

				<div class="row">
					<v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
						<v-col cols="12" lg="4" md="6" sm="12">
							<multiselect
								track-by="id"
								:rules="[(v) => !!v || 'El responsable es requerido']"
								label="name"
								placeholder="Seleccionar responsable"
								:custom-label="fullName"
								v-model="userId"
								:options="users"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="false"
								:preserve-search="false"
							></multiselect
						></v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field required :rules="descriptionRules" label="Nombre" v-model="name"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field required :rules="descriptionRules" label="Descripción" v-model="description"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field required :rules="emailRules" label="Email" v-model="email" type="email"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								v-mask="phoneMask"
								:rules="[rules.required, rules.maxNumber]"
								label="Teléfono"
								v-model="phone"
								type="text"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								v-mask="phoneMask"
								:rules="[rules.maxNumber]"
								label="Teléfono 2"
								v-model="phone2"
								type="text"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field required :rules="descriptionRules" label="Palabra clave" v-model="keyword"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								label="Sitio web"
								v-model="website"
								:rules="[rules.url]"
								persistent-hint
								:hint="'Ejemplo: https://google.com'"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" class="pt-0">
							<span>Países:</span>
							<multiselect
								track-by="id"
								:rules="[(v) => !!v || 'El país es requerido']"
								label="name"
								placeholder="Seleccionar países"
								v-model="countryId"
								:options="countries"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="true"
								:preserve-search="false"
							></multiselect>
						</v-col>
						<!--<v-col cols="12" lg="4" md="6" sm="12">
							<multiselect
								track-by="id"
								:rules="[(v) => !!v || 'La ciudad es requerida']"
								label="name"
								placeholder="Seleccionar ciudad"
								v-model="cityId"
								:options="cities"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="false"
								:preserve-search="false"
							></multiselect
						></v-col>-->
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field label="Latitude" v-model="latitude" type="number"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field label="Longitude" v-model="longitude" type="number"></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field required :rules="descriptionRules" label="Dirección" v-model="address"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6" md="6" sm="12">
							<div class="row" style="display: flex; justify-content: center; align-items: center">
								<img v-if="flow == 'edit' && item.logo" :src="item.logo.route" :alt="item.name" style="width: 40%" />
								<v-file-input
									v-model="image"
									chips
									:rules="fileRules"
									ref="file"
									@change="handleFileUpload()"
									show-size
									accept="image/webp, image/png, image/jpeg, image/jpg, image/gif"
									:label="'Seleccione nuevo logo'"
									style="width: 60%"
								></v-file-input>
							</div>
						</v-col>
						<v-col cols="12" lg="6" md="6" sm="12" class="pt-0">
							<span>Categorías:</span>
							<multiselect
								track-by="id"
								label="name"
								placeholder="Seleccionar"
								v-model="selectedCategories"
								:options="categories"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="false"
								:preserve-search="false"
							></multiselect>
						</v-col>
						<v-col cols="12" lg="3" md="6" sm="12" v-if="type == 0">
							<v-checkbox label=" Master disponible" v-model="master"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="3" md="6" sm="12">
							<v-checkbox label=" Destacada" v-model="outstanding"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="3" md="6" sm="12">
							<v-checkbox label=" Activa" v-model="active"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="3" md="6" sm="12">
							<v-checkbox label=" Mostar datos de contacto" v-model="showContact"></v-checkbox>
						</v-col>
					</v-form>
				</div>
			</div>
			<v-col cols="12" style="margin-bottom: 20px; height: 10%">
				<div class="row">
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						v-if="flow == 'create'"
						:disabled="!valid"
						color="#003a56"
						@click="createItem()"
					>
						Crear
					</v-btn>
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						v-if="flow == 'edit'"
						:disabled="!valid"
						color="#003a56"
						@click="saveItem()"
					>
						Guardar
					</v-btn>

					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						v-if="flow == 'edit'"
						:disabled="!valid"
						color="#003a56"
						@click="sendNotification()"
					>
						Notificar activación
					</v-btn>

					<v-btn style="margin: auto; margin-top: 10px; color: #fff" color="#003a56" @click="reset"> Limpiar </v-btn>
					<v-btn style="margin: auto; margin-top: 10px; color: #fff" color="#003a56" @click="$modal.hide('crudModal')">
						Cancelar
					</v-btn>
				</div>
			</v-col>
		</modal>
	</div>
</template>

<script>
	import SimpleList from 'Components/Listing/SimpleList.vue'
	import { utils, writeFileXLSX } from 'xlsx'

	export default {
		name: 'Companies',
		props: ['companyType'],
		components: {
			SimpleList
		},
		data() {
			return {
				config: {},
				companies: [],
				loading: false,
				headers: [
					{ text: 'ID', value: 'id', width: '60px' },
					{ text: 'Nombre', value: 'name' },
					{ text: 'Responsable', value: 'userFullName' },
					{ text: 'Destacado', value: 'outstandingText' },
					{ text: 'Activa', value: 'activeText' },
					{ text: 'Acciones', value: 'actions', align: 'center', width: '190px' }
				],
				typeDatas: [
					{ id: '2', name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
					{ id: '3', name: 'DATOS OPERATIVOS' },
					{ id: '4', name: 'INFORMACIÓN ECONÓMICA' },
					{ id: '5', name: 'OTROS DATOS IMPORTANTES' },
					{ id: '6', name: 'RESEÑA HISTÓRICA' },
					{ id: '7', name: 'DATOS DE CONTACTO' },
					{ id: '8', name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
					{ id: '9', name: 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO' }
				],
				selectCompanyType: [
					{ id: 0, name: 'Franquicias' },
					{ id: 1, name: 'Asociaciones de Franquicias' },
					{ id: 2, name: 'Consultores' },
					{ id: 3, name: 'Proveedores' },
					{ id: 4, name: 'Oferta Inmobiliaria' }
				],
				items: [],
				categories: [],
				selectedCategories: [],
				page: 1,
				pages: 1,
				item: {},
				totalItems: 0,
				itemsPerPage: 10,
				editText: '',
				flow: '',
				filterActivated: false,
				orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
				orderOptions: [
					{ id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
					{ id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
					{ id: 3, name: 'Nombre', order: 'name', by: 'asc' },
					{ id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' }
				],
				filters: {
					userId: null,
					search: '',
					date: { startAt: '', endAt: '' },
					outstanding: null,
					active: null,
					countryId: null
				},
				activeOptions: [
					{ id: null, name: 'Todas' },
					{ id: 1, name: 'Activas' },
					{ id: 0, name: 'Inactivas' }
				],
				outstandingOptions: [
					{ id: null, name: 'Todas' },
					{ id: 1, name: 'Destacadas' },
					{ id: 0, name: 'No Destacadas' },
					{ id: 2, name: 'Master' }
				],
				phoneMask: [
					'+',
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/
				],
				active: false,
				type: 0,
				address: '',
				cityId: '',
				email: '',
				keyword: '',
				latitude: '',
				longitude: '',
				link: '',
				phone: '',
				name: '',
				userId: '',
				description: '',
				master: false,
				phone2: '',
				website: '',
				outstanding: false,
				showContact: false,
				cities: [],
				countries: [{ id: null, name: 'Todos' }],
				users: [{ id: null, name: 'Todos' }],
				createdAt: '',
				toDay: new Date(),
				dateRange: '',
				countryId: '',
				selectCountryData: [],
				selectCityData: [],
				loadingText: 'Cargando registros',
				datas: [],
				image: null,
				arrayOfData: [],
				valid: true,
				excelCompanies: null,
				progressValue: 0,
				loadingExcel:null,
				descriptionRules: [(v) => !!v || 'Requerido', (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres'],
				emailRules: [(v) => !!v || 'E-mail es requerido', (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido'],
				rules: {
					required: (v) => !!v || 'Requerido.',
					min: (v) => v.length >= 8 || '8 caracteres como mínimo',
					max: (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres',
					maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
					url: (v) => this.isURL(v) || 'La URL es inválida'
				},
				fileRules: [(value) => !value || value.size < 5000000 || 'El peso del archivo debe ser inferior a 5MB'],
				extraButtoms: [
					{
						text: 'Vista previa',
						link: 'CompanyDetail',
						icon: 'mdi-eye',
						query: { name: 'slug', value: 'link' },
						typeLink: 'name'
					},
					// {
					// 	text: 'Exportar datos',
					// 	link: 'createExcel',
					// 	icon: 'mdi-microsoft-excel',
					// 	query: null,
					// 	typeLink: 'event'
					// },
					{
						text: 'Descargar QR',
						link: 'handleQR',
						icon: 'mdi-qrcode',
						query: null,
						typeLink: 'event'
					},
					{
						text: 'Datos publicados',
						link: 'empresas/ficha',
						icon: 'mdi-file-document-edit',
						query: { name: 'id', value: 'id' },
						typeLink: 'path'
					},
					{
						text: 'Galería de imágenes',
						link: 'empresas/galeria',
						icon: 'mdi-image-multiple',
						query: { name: 'id', value: 'id' },
						typeLink: 'path'
					}
				]
			}
		},
		watch: {
			companyType(newValue, oldValue) {
				this.getCompanies()
			}
		},
		created() {
			this.getCompanies()
			this.getCountries()
			this.getUsers()
			this.getCategories()
		},
		computed: {
			title() {
				switch (this.companyType) {
					case 0:
						this.editText = 'Franquicia'
						return 'Franquicias'
					case 1:
						this.editText = 'Asociación de Franquicia'
						return 'Asociaciones de Franquicias'
					case 2:
						this.editText = 'Consultor'
						return 'Consultores'
					case 3:
						this.editText = 'Proveedor'
						return 'Proveedores'
					case 4:
						this.editText = 'Oferta Inmobiliaria'
						return 'Oferta Inmobiliaria'
					case 5:
						this.editText = 'Negocio'
						return 'Negocios'
					default:
						return ''
				}
			}
		},
		methods: {
			handleOrder(orderBy) {
				this.orderBy = orderBy
				this.getCompanies()
			},
			isURL(str) {
				if (!str || str === '') return true
				let url
				try {
					url = new URL(str)
				} catch (_) {
					return false
				}

				return url.protocol === 'http:' || url.protocol === 'https:'
			},
			fullName({ name, lastname, email }) {
				if (name && name != 'Todos') {
					if (lastname && email) {
						return `${name} ${lastname} | ${email}`
					} else {
						return name
					}
				} else {
					return name
				}
			},
			countryName({ name, iso2 }) {
				if (name && iso2) {
					return `${name} (${iso2})`
				} else if (name) {
					return name
				}
			},
			handleFileUpload() {
				if (this.$refs.file.files) {
					this.image = this.$refs.file.files[0]
				}
			},
			cleanFilters() {
				this.filters = {
					userId: null,
					search: '',
					outstanding: null,
					active: null,
					countryId: null,
					date: {}
				}
			},
			async getUsers() {
				axios.get(process.env.VUE_APP_API_DIRECTORY + 'users?limit=1000').then((response) => {
					this.users = this.users.concat(response.data)
					this.users.map((x) => {
						if (x.lastname) {
							x.fullName = x.name + ' ' + x.lastname + ' | ' + x.email
						}
					})
				})
			},
			async getCompanies() {
				this.loading = true
				let queryParams = ''
				let orderString = ''
				if (this.orderBy.order && this.orderBy.by) {
					orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
				}
				if (this.filterActivated) {
					let searchString = ''
					let userString = ''
					let countryString = ''
					let outstandingString = ''
					let masterString = ''
					let activeString = ''
					let dateString = ''
					if (this.filters.date.startAt != '' && this.filters.date.endAt != '') {
						dateString = '&startAt=' + this.filters.date.startAt + '&endAt=' + this.filters.date.endAt
					}
					if (this.filters.search != '') {
						searchString = '&search=' + this.filters.search
					}
					if (this.filters.userId && this.filters.userId.id) {
						userString = '&user_id=' + this.filters.userId.id
					}
					if (this.filters.countryId && this.filters.countryId.id) {
						countryString = '&country_id=' + this.filters.countryId.id
					}
					if (this.filters.outstanding && this.filters.outstanding.id) {
						if (this.filters.outstanding.id == 2) {
							masterString = '&master=1'
						} else {
							outstandingString = '&outstanding=' + this.filters.outstanding.id
						}
					}
					if (this.filters.active && this.filters.active.id == 1) {
						activeString = '&active=1'
					} else if (this.filters.active && this.filters.active.id == 0) {
						activeString = '&active=0'
					}
					queryParams =
						searchString + userString + countryString + outstandingString + activeString + masterString + dateString
				}
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY +
							'companies?page=' +
							this.page +
							'&type=' +
							this.companyType +
							queryParams +
							orderString
					)
					.then((response) => {
						this.items = response.data.data
						// this.totalItems = response.data.total
						if (this.items.length < 1) {
							this.loadingText = 'No hay registros'
						}
						this.totalItems = response.data.total
						this.itemsPerPage = response.data.per_page
						if (response.data.from) {
							this.page = response.data.current_page
							this.pages = response.data.last_page
						} else {
							this.page = 1
							this.pages = 1
						}
						this.items.map((x) => {
							let activeText
							switch (x.active) {
								case 1:
									activeText = 'Si'
									break
								default:
									activeText = 'No'
									break
							}
							x.activeText = activeText
							let outstandingText
							switch (x.outstanding) {
								case 1:
									outstandingText = 'Si'
									break
								default:
									outstandingText = 'No'
									break
							}
							x.outstandingText = outstandingText
							x.userFullName = x.user.name + ' ' + x.user.lastname + ' | ' + x.user.email
						})
						this.loading = false
					})
			},

			async getCategories() {
				axios.get(process.env.VUE_APP_API_DIRECTORY + 'categories?limit=300&type=0').then((response) => {
					this.categories = response.data
				})
			},
			async getCities() {
				axios
					.get(process.env.VUE_APP_API_DIRECTORY + 'cities?limit=500&country=' + this.countryId.id)
					.then((response) => {
						this.cities = response.data
						if (this.flow == 'edit') {
							this.cityId = this.cities.filter((city) => city.id == this.item.city_id).shift()
						}
					})
			},
			async getCountries() {
				axios.get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300' + this.countryId).then((response) => {
					this.countries = this.countries.concat(response.data)
				})
			},
			async sendNotification() {
				axios
					.post(process.env.VUE_APP_API_DIRECTORY + 'company-management/notify-company', {
						company_id: this.item.id
					})
					.then(() => {
						this.$swal({
							title: '¡Listo!',
							text: 'La notificación ha sido enviada',
							icon: 'success'
						})
					})
			},
			async handleCreate() {
				this.flow = 'create'
				this.item = {}
				this.name = ''
				this.userId = ''
				this.description = ''
				this.email = ''
				this.phone = ''
				this.phone2 = ''
				this.latitude = ''
				this.longitude = ''
				this.keyword = ''
				this.website = ''
				this.cityId = ''
				this.address = ''
				this.countryId = ''
				this.showContact = false
				this.active = false
				this.master = false
				this.outstanding = false
				this.selectedCategories = []

				this.$modal.show('crudModal')
			},
			async handleEdit(item) {
				this.flow = 'edit'
				this.item = item
				this.image = null
				this.userId = this.users.filter((user) => user.id == this.item.user_id).shift()
				this.name = this.item.name
				this.description = this.item.description
				this.email = this.item.email
				this.phone = this.item.phone
				this.phone2 = this.item.phone2
				this.latitude = this.item.latitude
				this.longitude = this.item.longitude
				this.keyword = this.item.keyword
				this.website = this.item.website
				this.address = this.item.address
				this.countryId = this.item.countries
				this.showContact = this.item.showcontact == 0 ? false : true
				this.active = this.item.active == 0 ? false : true
				if (this.item.type != 0) {
					this.master = false
				} else {
					this.master = this.item.master == 0 ? false : true
				}
				this.outstanding = this.item.outstanding == 0 ? false : true
				this.selectedCategories = this.item.categories
				this.createdAt = new Date(this.item.created_at)
				this.dateRange = Math.ceil(Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24))
				this.$modal.show('crudModal')
			},
			handleQR(company) {
				const link = document.createElement('a')
				link.href =
					'https://api.qrserver.com/v1/create-qr-code/?data=' +
					process.env.VUE_APP_WEBSITE +
					'/empresa/' +
					company.link +
					'?contact=1&size=500x500&format=svg'
				link.target = '_blank'
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			},
			validate() {
				this.$refs.formCompany.validate()
			},

			reset() {
				this.$refs.formCompany.reset()
			},
				async getCompaniesExcel() {
				// this.loadingCompanies = true
				let queryParams = ''
				let searchString = ''
				let userString = ''
				let countryString = ''
				let outstandingString = ''
				let masterString = ''
				let activeString = ''
				let dateString = ''
				let orderString = ''
				const itemsPerPage = 50
				const itemsPerPageStr = `&itemsPerPage=${itemsPerPage}`
				// let pageString = `&page=${this.page}`
				let companyTypeString = `&type=${this.companyType}`
				if (this.orderBy.order && this.orderBy.by) {
					orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
				}
				if (this.filterActivated) {
					if (this.filters.date.startAt != '' && this.filters.date.endAt != '') {
						dateString = '&startAt=' + this.filters.date.startAt + '&endAt=' + this.filters.date.endAt
					}
					if (this.filters.search != '') {
						searchString = '&search=' + this.filters.search
					}
					if (this.filters.userId && this.filters.userId.id) {
						userString = '&user_id=' + this.filters.userId.id
					}
					if (this.filters.countryId && this.filters.countryId.id) {
						countryString = '&country_id=' + this.filters.countryId.id
					}
					if (this.filters.outstanding && this.filters.outstanding.id) {
						if (this.filters.outstanding.id == 2) {
							masterString = '&master=1'
						} else {
							outstandingString = '&outstanding=' + this.filters.outstanding.id
						}
					}
					if (this.filters.active && this.filters.active.id == 1) {
						activeString = '&active=1'
					} else if (this.filters.active && this.filters.active.id == 0) {
						activeString = '&active=0'
					}
				}
				queryParams =
					searchString +
					userString +
					countryString +
					outstandingString +
					activeString +
					masterString +
					dateString +
					// pageString +
					companyTypeString +
					itemsPerPageStr +
				orderString
				try {
					this.excelCompanies = []
					for (let i = 1; i <= Math.ceil(this.totalItems / itemsPerPage); i++) {
						const response = await axios.get(`companies?${queryParams}&page=${i}`)
						const data = response.data.data
						data.forEach((company) => {
							company.countriesStr = company.countries.map(country => country.name).join(', ')
							company.categoriesStr = company.categories.map(category => category.name).join(', ')
							this.excelCompanies.push(company)
						})
						this.progressValue = (itemsPerPage * i) / this.totalItems
					}
					// this.loadingCompanies = false
				} catch (error) {
					throw new Error(error)
				}
			},
			async exportExcel() {
				// if (!this.handleAssignedRoles(this.roles, ['super_admin', 'general_admin_orders'])) {
				// if (!this.filters.startAt) {
				// 	this.$swal({
				// 		title: 'Selecciona fecha de inicio',
				// 		icon: 'info'
				// 	})
				// 	return
				// }
				// if (!this.filters.endAt) {
				// 	this.$swal({
				// 		title: 'Selecciona fecha final',
				// 		icon: 'info'
				// 	})
				// 	return
				// }
				// if (moment(this.filters.endAt).diff(moment(this.filters.startAt), 'days') > 31) {
				// 	this.$swal({
				// 		title: 'El intervalo de fecha disponible es 31 dias máximo',
				// 		icon: 'info'
				// 	})
				// 	return
				// }
				this.loadingExcel = true
				await this.getCompaniesExcel()
				const data = this.excelCompanies.map((company) => {
					return {
						Id: `${company.id}`,
						Nombre: company.name ? company.name : '',
						Descripción: company.description ? company.description : '',
						Email: company.email ? company.email : '',
						Teléfono: company.phone ? company.phone : '',
						'Teléfono 2': company.phone ? company.phone2 : '',
						Keywords: company.keyword ? company.keyword : '',
						'Sitio web': company.website ? company.website : '',
						Paises: company.countriesStr,
						Categorias: company.categoriesStr,
						'Master disponible': company.master ? 'Si' : 'No' ,
						'Destacada': company.outstanding ? 'Si' : 'No' ,
						'Activa': company.Active ? 'Si' : 'No' ,
						Dirección: company.address ? company.address : '',
						Latitude: company.latitude ? company.latitude : '',
						Longitude: company.longitude ? company.longitude : '',
						Logo: company.logo ? company.logo.route : '',
					}
				})
				const ws = utils.json_to_sheet(data)
				const wb = utils.book_new()
				utils.book_append_sheet(wb, ws, 'Franquicias')
				writeFileXLSX(wb, `Franquicias_${new Date().toLocaleString('en-GB')}.xlsx`) // * We used the en-GB locale in a hacky way to format the date as DD/MM/YYYY
				setTimeout(() => {
					this.progressValue = 0
				}, 1000)
				this.loadingExcel = false
			},
		
			async createItem() {
				let data = []
				let formData = new FormData()
				this.showContact = this.showContact ? 1 : 0
				this.active = this.active ? 1 : 0
				this.master = this.master ? 1 : 0
				this.outstanding = this.outstanding ? 1 : 0
				/*this.arrayOfData.forEach((level1) => {
  			level1.data.forEach((level2) => {
  				if (level2.value != '') {
  					data.push({ data_id: level2.id, value: level2.value, count: 1 })
  				}
  			})
  		})*/
				this.config.headers = {
					'Content-Type': 'multipart/form-data'
				}
				if (this.image) {
					formData.append('file', this.image)
				}
				formData.append('fileType', 'logo')
				formData.append('name', this.name)
				formData.append('user_id', this.userId.id)
				formData.append('type', this.companyType)
				formData.append('description', this.description)
				if (this.website && this.website != '') {
					formData.append('website', this.website.toLowerCase())
				}
				if (this.latitude && this.latitude != '') {
					formData.append('latitude', this.latitude)
				}
				if (this.longitude && this.longitude != '') {
					formData.append('longitude', this.longitude)
				}
				formData.append('address', this.address)
				if (this.keyword && this.keyword != '') {
					formData.append('keyword', this.keyword.toLowerCase())
				}
				formData.append('phone', this.phone)
				if (this.phone2 && this.phone2 != '') {
					formData.append('phone2', this.phone2)
				}
				formData.append('email', this.email)
				formData.append('showcontact', this.showContact)
				formData.append('active', this.active)
				formData.append('outstanding', this.outstanding)
				formData.append('master', this.master)
				this.selectedCategories.forEach((category) => {
					formData.append('categories[]', category.id)
				})
				this.countryId.forEach((country) => {
					formData.append('countries[]', country.id)
				})
				data.forEach((singleData) => {
					formData.append('data[]', singleData)
				})

				axios.post(process.env.VUE_APP_API_DIRECTORY + 'companies', formData, this.config).then((response) => {
					this.page = 1
					this.getCompanies()
					this.$modal.hide('crudModal')
				})
			},

			async saveItem() {
				let data = []
				let formData = new FormData()
				this.showContact = this.showContact ? 1 : 0
				this.active = this.active ? 1 : 0
				this.master = this.master ? 1 : 0
				this.outstanding = this.outstanding ? 1 : 0
				this.config.headers = {
					'Content-Type': 'multipart/form-data'
				}
				if (this.image) {
					formData.append('file', this.image)
				}
				if (this.item.logo) {
					formData.append('fileId', this.item.logo.id)
				}
				formData.append('fileType', 'logo')
				formData.append('_method', 'patch')
				formData.append('name', this.name)
				formData.append('user_id', this.userId.id)
				formData.append('type', this.companyType)
				formData.append('description', this.description)
				if (this.website && this.website != '') {
					formData.append('website', this.website.toLowerCase())
				}
				if (this.latitude && this.latitude != '') {
					formData.append('latitude', this.latitude)
				}
				if (this.longitude && this.longitude != '') {
					formData.append('longitude', this.longitude)
				}
				formData.append('address', this.address)
				if (this.keyword && this.keyword != '') {
					formData.append('keyword', this.keyword.toLowerCase())
				}
				formData.append('phone', this.phone)
				if (this.phone2 && this.phone2 != '') {
					formData.append('phone2', this.phone2)
				}
				formData.append('email', this.email)
				formData.append('showcontact', this.showContact)
				formData.append('active', this.active)
				formData.append('outstanding', this.outstanding)
				formData.append('master', this.master)
				this.selectedCategories.forEach((category) => {
					formData.append('categories[]', category.id)
				})
				this.countryId.forEach((country) => {
					formData.append('countries[]', country.id)
				})
				data.forEach((singleData) => {
					formData.append('data[]', singleData)
				})

				axios
					.post(process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.item.id, formData, this.config)
					.then((response) => {
						this.page = 1
						this.getCompanies()
						this.$modal.hide('crudModal')
					})
			},

			async deleteItem(item) {
				axios.delete(process.env.VUE_APP_API_DIRECTORY + 'companies/' + item.id).then(() => {
					this.page = 1
					this.items = []
					this.getCompanies()
				})
			},

			async pagination(page) {
				this.page = page
				this.items = []
				await this.getCompanies()
			}
		}
	}
</script>
<style lang="scss">
	@import '@/assets/scss/cruds/_crudStyles.scss';
</style>
